<script>
import AntInput from '@/components/AntInput.vue';
import { mapGetters } from 'vuex';
import { executeCustomModuleCall } from '@/services/api/module.api';

export default {
  name: 'DhmeStationDashboardCompleteAssemblyDialog',
  components: { AntInput },
  props: {
    displayed: {
      type: Boolean,
      required: true,
    },
  },
  data: () => {
    return {
      isLoading: false, // Loading state
    };
  },
  computed: {
    ...mapGetters({
      project: 'project',
      focusedTask: 'dhmeStationDashboard/focusedAssemblyTask',
      moduleId: 'dhmeStationDashboard/moduleId',
    }),

    showModal() {
      return this.displayed;
    },
  },
  methods: {
    closeDialog() {
      // Close dialog only if not loading
      if (!this.isLoading) {
        this.$emit('close');
      }
    },
    async completeAssembly() {
      this.isLoading = true; // Start loading state
      try {
        await executeCustomModuleCall(
          this.project.id,
          this.moduleId,
          'completeAssembly',
          {
            task: this.focusedTask.id,
          }
        );
        this.focusedTask.status = 'closed';
        this.$emit('close');
        this.$emit('completed');
      } catch (e) {
        this.$store.commit('showNotification', {
          content: e.message,
          color: 'error',
        });
      } finally {
        this.isLoading = false; // Stop loading state
      }
    },
  },
};
</script>

<template>
  <v-dialog v-model="showModal" max-width="600px">
    <v-card>
      <v-card-title class="justify-center text-uppercase headline">
        Complete assembly for module
        {{ focusedTask?.assemblyItem?.id }}?
      </v-card-title>
      <v-card-text>
        <v-card-subtitle>
          This will move the item out of the assembly hall
        </v-card-subtitle>
      </v-card-text>
      <v-card-actions class="ant-border-top ant-dialog-actions-bg">
        <v-spacer />
        <!-- Cancel button disabled when loading -->
        <v-btn
          :disabled="isLoading"
          color="error"
          small
          text
          @click="closeDialog"
        >
          Cancel
        </v-btn>
        <!-- Complete button updates loading spinner and disables when loading -->
        <v-btn
          :loading="isLoading"
          :disabled="isLoading"
          color="primary"
          elevation="0"
          small
          @click="completeAssembly"
        >
          Complete
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="scss" scoped>
::v-deep .v-chip__content {
  display: inline-block !important;
  white-space: nowrap;
  overflow: hidden;
  height: auto;
  text-overflow: ellipsis;
}

::v-deep .v-chip__close {
  position: absolute;
  top: 5px;
  right: 0;
  width: 24px;
}
</style>
