<script>
import AntInput from '@/components/AntInput.vue';
import { mapGetters } from 'vuex';
import { createUUID } from '@/services/uuid-helper';
import { LaravelEchoClient } from '@/services/laravel-echo';
import { getBlackOrWhiteBasedOnBackground } from '@/services/color-helper';

export default {
  name: 'DhmeStationDashboardSelectLocationDialog',
  components: { AntInput },
  props: {
    displayed: {
      type: Boolean,
      required: true,
    },
  },
  data: () => {
    return {
      hallType: 'assembly',
      focusedHall: null,
      focusedConfiguration: null,
      focusedLane: null,
      focusedLocation: null,
      linkId: null,
    };
  },
  computed: {
    ...mapGetters({
      project: 'project',
      moduleId: 'dhmeStationDashboard/moduleId',
      halls: 'dhmeStationDashboard/halls',
      locations: 'dhmeStationDashboard/assemblyLocations',
      selectedHall: 'dhmeStationDashboard/selectedHall',
      isLoading: 'dhmeStationDashboard/selectedHallLoading',
      selectedLocation: 'dhmeStationDashboard/selectedLocation',
      hallLocations: 'dhmeStationDashboard/selectedHallLocations',
      hallLanes: 'dhmeStationDashboard/selectedHallLanes',
      hallConfigurations:
        'dhmeStationDashboard/selectedAssemblyHallConfigurations',
    }),
    selectedAssemblyHallLocationLanes() {
      return this.hallLanes
        .filter((x) =>
          this.hallLocations.some((l) => l.location.assembly_lane === x.id)
        )
        .sort((a, b) => a.order - b.order);
    },
    hallLocationsByConfigurationAndLane() {
      let locations = this.selectedHall?.automated
        ? this.hallLocations
        : this.hallLocations.filter(
            (l) => l.location.assembly_lane === this.focusedLane
          );

      return locations.sort(
        (a, b) => parseInt(a.location.order) - parseInt(b.location.order)
      );
    },
    showModal() {
      return this.displayed;
    },
    filteredHalls() {
      return this.halls.filter((h) => h.type === this.hallType);
    },
  },
  watch: {
    hallType(value) {
      this.focusedHall = null;
      this.focusedLane = null;
      this.focusedLocation = null;
      this.focusedConfiguration = null;
    },
    displayed(value) {
      if (value) {
        if (this.selectedHall) {
          this.focusedHall = this.selectedHall;
          this.$store.dispatch(
            'dhmeStationDashboard/selectStationDashboardHall',
            this.selectedHall
          );
        } else {
          this.focusedHall = null;
        }
        this.focusedLane = null;
        this.focusedLocation = null;
        this.focusedConfiguration = null;
        this.$store.dispatch('dhmeStationDashboard/fetchHalls');
      }
    },
  },
  mounted() {
    this.linkId = createUUID();
    const channelName = `App.Project.${this.project.id}.Module.${this.moduleId}.Link.${this.linkId}`;
    const channel = LaravelEchoClient.private(channelName);

    const eventHandler = async (event, content) => {
      switch (event) {
        case 'DhmeLinkDevices':
          await this.$router.push({
            path: this.$route.path,
            query: { location: content.data.id },
          });
          // Get the current URL
          const currentUrl = new URL(window.location.href);

          // Add a timestamp as a query parameter to force cache clearing
          const timestamp = new Date().getTime();
          currentUrl.searchParams.set('timestamp', timestamp);

          // Modify the current URL without adding to the browser's history
          window.history.replaceState({}, document.title, currentUrl.href);

          // Reload the page
          window.location.reload(); // Pass true to force a hard reload
          break;
      }
    };

    // Add event listener for the reconnect event
    LaravelEchoClient.connector.pusher.connection.bind('connected', () => {
      console.log('WebSocket connected');
      // Re-subscribe to the channel after reconnection
      channel.stopListening(channelName);
      channel.listenToAll(eventHandler);
    });

    // Add event listener for the disconnect event
    LaravelEchoClient.connector.pusher.connection.bind('disconnected', () => {
      console.log('WebSocket disconnected');
    });

    // Subscribe to the channel
    channel.listenToAll(eventHandler);
  },
  beforeDestroy() {
    LaravelEchoClient.leave(
      `App.Project.${this.project.id}.Module.${this.moduleId}.Link.${this.linkId}`
    );
  },
  methods: {
    getBlackOrWhiteBasedOnBackground,
    selectAssemblyHall(hall) {
      this.$store.dispatch(
        'dhmeStationDashboard/selectStationDashboardHall',
        hall
      );
    },
    selectAssemblyLocation(item) {
      this.$store.dispatch(
        'dhmeStationDashboard/selectStationDashboardLocation',
        item.location
      );
      this.$router.push({
        path: this.$route.path,
        query: { location: item.location.id },
      });
    },
    toDashboard() {
      this.$router.push({
        name: 'Dashboard',
      });
    },
  },
};
</script>

<template>
  <v-dialog v-model="showModal" max-width="600px" persistent>
    <v-card>
      <v-card-title class="justify-center text-uppercase headline">
        Select hall location
      </v-card-title>
      <v-divider />
      <div class="px-10 pb-5">
        <v-form
          ref="stationDashboardLocationForm"
          class="d-flex align-center justify-center"
        >
          <div class="flex-1">
            <v-btn-toggle
              v-model="hallType"
              class="full-width"
              color="primary"
              group
              mandatory
              tile
            >
              <v-btn style="flex: 1 !important" value="assembly">
                ASSEMBLY
              </v-btn>
              <v-btn style="flex: 1 !important" value="production">
                PRODUCTION
              </v-btn>
            </v-btn-toggle>
            <ant-input label="Hall" style="margin-top: 0 !important">
              <template #input-field>
                <v-select
                  v-model="focusedHall"
                  :items="filteredHalls"
                  dense
                  filled
                  hide-details
                  item-text="title"
                  item-value="id"
                  placeholder="Hall"
                  return-object
                  @input="selectAssemblyHall"
                >
                  <template #item="{ item }">
                    {{ item.title }}
                    <v-spacer />
                    <v-chip class="mr-2" small>
                      <v-icon left small
                        >{{
                          item.automated
                            ? 'mdi-robot-industrial'
                            : 'mdi-account-wrench'
                        }}
                      </v-icon>
                      {{ item.automated ? 'Automated' : 'Manual' }}
                    </v-chip>
                  </template>
                </v-select>
              </template>
            </ant-input>

            <ant-input v-if="focusedHall?.automated === 0" label="Lane">
              <template #input-field>
                <v-select
                  v-model="focusedLane"
                  :disabled="!focusedHall || isLoading"
                  :items="selectedAssemblyHallLocationLanes"
                  :loading="isLoading"
                  dense
                  filled
                  hide-details
                  item-text="title"
                  item-value="id"
                  placeholder="Lane"
                >
                  <template #item="{ item }">
                    <v-chip
                      :color="item.color"
                      :text-color="getBlackOrWhiteBasedOnBackground(item.color)"
                      class="mr-5"
                      >{{ item.title }}
                    </v-chip>
                  </template>
                </v-select>
              </template>
            </ant-input>

            <ant-input label="Location">
              <template #input-field>
                <v-select
                  v-model="focusedLocation"
                  :disabled="
                    focusedHall?.automated
                      ? !focusedHall
                      : !focusedLane || isLoading
                  "
                  :items="hallLocationsByConfigurationAndLane"
                  :loading="isLoading"
                  dense
                  filled
                  hide-details
                  item-value="assembly_location"
                  placeholder="Location"
                  return-object
                  @input="selectAssemblyLocation"
                >
                  <template #item="{ item }">
                    <div class="d-flex align-center full-width">
                      <span class="font-italic fs-12 mr-2">{{
                        item.location.order
                      }}</span>
                      {{ item.location.assembly_location }}
                      <v-spacer />
                      <v-tooltip right>
                        <template #activator="{ on, attrs }">
                          <v-chip
                            label
                            outlined
                            style="max-width: 150px"
                            v-bind="attrs"
                            x-small
                            v-on="on"
                            >{{ item.project.name }}
                          </v-chip>
                        </template>
                        <span>{{ item.project.number }}</span>
                      </v-tooltip>
                    </div>
                  </template>
                </v-select>
              </template>
            </ant-input>
          </div>
          <div class="d-flex justify-center align-center">
            <qr-code :options="{ width: 250 }" :value="linkId" />
          </div>
        </v-form>
      </div>
      <v-card-actions class="ant-border-top ant-dialog-actions-bg">
        <v-spacer />
        <v-btn color="error" small text @click.stop="toDashboard">
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="scss" scoped>
::v-deep .v-chip__content {
  display: inline-block !important;
  white-space: nowrap;
  overflow: hidden;
  height: auto;
  text-overflow: ellipsis;
}

::v-deep .v-chip__close {
  position: absolute;
  top: 5px;
  right: 0;
  width: 24px;
}
</style>
