<script>
import { mapActions, mapGetters } from 'vuex';
import { LaravelEchoClient } from '@/services/laravel-echo';

export default {
  name: 'DhmeStationDashboardToolbar',
  props: {
    websocketsConnected: {
      type: Boolean,
      default: true,
    },
  },
  data: () => {
    return {
      networkStatus: true,
      focusedLanguage: 'en',
    };
  },
  computed: {
    ...mapGetters({
      project: 'project',
      selectedLocation: 'dhmeStationDashboard/selectedLocation',
      selectedHall: 'dhmeStationDashboard/selectedHall',
      selectedLane: 'dhmeStationDashboard/selectedLane',
      moduleId: 'dhmeStationDashboard/moduleId',
      showTasks: 'dhmeStationDashboard/showTaskSidebar',
      showPlanning: 'dhmeStationDashboard/showPlanningSidebar',
      languages: 'dhmeStationDashboard/supportedLanguages',
      language: 'dhmeStationDashboard/selectedLanguage',
    }),
  },
  watch: {
    focusedLanguage(value) {
      if (value) {
        this.$store.commit('dhmeStationDashboard/set_language', value);
      }
    },
  },
  mounted() {
    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);
  },
  beforeDestroy() {
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);

    this.closeLocationListeners();
    this.resetStore();
  },
  methods: {
    ...mapActions({
      toggleTasksSidebar: 'dhmeStationDashboard/toggleTasksSidebar',
      togglePlanningSidebar: 'dhmeStationDashboard/togglePlanningSidebar',
    }),
    updateOnlineStatus(e) {
      const { type } = e;
      this.networkStatus = type === 'online';
      this.$store.commit('showNotification', {
        content: `Network is ${type}`,
        color: this.networkStatus ? 'success' : 'warning',
      });
    },
    async reloadData() {
      await this.$store.dispatch(
        'dhmeStationDashboard/fetchStationDashboardModuleAssemblyTasks'
      );
      await this.$store.dispatch(
        'dhmeStationDashboard/fetchAssemblySubTasksByLocation'
      );
      await this.$store.dispatch(
        'dhmeStationDashboard/fetchStationDashboardCurrentTact'
      );

      LaravelEchoClient.private(
        `App.Project.${this.project.id}.Module.${this.moduleId}.Location.${this.selectedLocation.id}`
      ).whisper('reloadData', true);
    },
    resetStore() {
      this.$nextTick(() => {
        this.$store.commit('dhmeStationDashboard/reset_assembly_location');
        this.$store.commit(
          'dhmeStationDashboard/reset_location_assembly_tasks'
        );
      });
    },
    closeLocationListeners() {
      if (this.selectedLocation) {
        LaravelEchoClient.leave(
          `App.Project.${this.project.id}.Module.${this.moduleId}.Hall.${this.selectedLocation.assembly_hall}`
        );
        LaravelEchoClient.leave(
          `App.Project.${this.project.id}.Module.${this.moduleId}.Location.${this.selectedLocation.id}`
        );
      }
    },
    changeLocation() {
      this.closeLocationListeners();
      this.resetStore();
    },
  },
};
</script>

<template>
  <div class="station-dashboard-toolbar-container">
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <div class="d-flex align-center mr-5" v-bind="attrs" v-on="on">
          <v-icon class="mr-2">mdi-warehouse</v-icon>
          {{ selectedHall.title }}
        </div>
      </template>
      <span>Hall</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <div
          class="d-flex align-center mr-5 c-pointer"
          v-bind="attrs"
          @click="changeLocation"
          v-on="on"
        >
          <v-icon class="mr-2">mdi-network</v-icon>
          <span>{{ selectedLocation?.assembly_location ?? null }} </span>
          <span
            v-if="selectedLocation.description"
            class="font-italic ml-2 fs-14"
            >{{ selectedLocation.description }}</span
          >
        </div>
      </template>
      <span>Location (click to change)</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-chip class="mx-2" v-bind="attrs" v-on="on">
          <v-icon left
            >{{
              selectedHall?.automated
                ? 'mdi-robot-industrial'
                : 'mdi-account-wrench'
            }}
          </v-icon>
          {{ selectedHall.automated ? 'Automated' : 'Manual' }}
        </v-chip>
      </template>
      <span>Assembly hall state</span>
    </v-tooltip>
    <v-tooltip v-if="!selectedHall?.automated && selectedLane" bottom>
      <template #activator="{ on, attrs }">
        <v-chip class="mx-2" v-bind="attrs" v-on="on">
          <v-icon :color="selectedLane.color" class="mr-2"
            >mdi-minus-thick
          </v-icon>
          {{ selectedLane.title }}
        </v-chip>
      </template>
      <span>Assembly Lane</span>
    </v-tooltip>

    <v-spacer />

    <v-select
      v-if="languages.length > 0"
      v-model="focusedLanguage"
      :items="languages"
      dense
      filled
      hide-details
      prepend-inner-icon="mdi-flag"
      style="max-width: 120px"
    />

    <v-tooltip left>
      <template #activator="{ on }">
        <v-btn
          :color="showPlanning ? 'primary' : ''"
          class="mr-2"
          icon
          tile
          @click="togglePlanningSidebar"
          v-on="on"
        >
          <v-icon>
            {{
              showPlanning ? 'mdi-calendar-start' : 'mdi-calendar-start-outline'
            }}
          </v-icon>
        </v-btn>
      </template>
      <span>{{
        `${showPlanning ? 'Hide' : 'Show'} planning sidebar (left)`
      }}</span>
    </v-tooltip>
    <v-tooltip left>
      <template #activator="{ on }">
        <v-btn
          :color="showTasks ? 'primary' : ''"
          class="mr-2"
          icon
          tile
          @click="toggleTasksSidebar"
          v-on="on"
        >
          <v-icon>
            {{
              showTasks
                ? 'mdi-clipboard-multiple'
                : 'mdi-clipboard-multiple-outline'
            }}
          </v-icon>
        </v-btn>
      </template>
      <span>{{ `${showTasks ? 'Hide' : 'Show'} task sidebar (right)` }}</span>
    </v-tooltip>
    <v-tooltip left>
      <template #activator="{ on }">
        <v-btn class="mr-2" icon tile @click="reloadData" v-on="on">
          <v-icon> mdi-reload</v-icon>
        </v-btn>
      </template>
      <span>Reload</span>
    </v-tooltip>
    <v-tooltip left>
      <template #activator="{ on }">
        <v-icon
          :color="networkStatus && websocketsConnected ? 'success' : 'warning'"
          v-on="on"
        >
          {{ networkStatus ? 'mdi-web-check' : 'mdi-web-cancel' }}
        </v-icon>
      </template>
      <span>Network/Socket Status</span>
    </v-tooltip>
  </div>
</template>

<style lang="scss" scoped>
.station-dashboard-toolbar-container {
  background-color: white;
  border-bottom: solid 1px lightgrey;
  height: 70px;
  display: flex;
  align-items: center;
  padding: 0 15px;
}
</style>
